import { computed } from 'vue'
import { useRouter } from 'vue-router'

export function useParams() {
   const router = useRouter()

   const customerId = computed(
      () => router.currentRoute.value.params.customerId as string
   )

   const projectId = computed(
      () => router.currentRoute.value.params.projectId as string
   )

   const dashboardId = computed(
      () => router.currentRoute.value.params.dashboardId as string
   )

   const repositoryInfoId = computed(
      () => router.currentRoute.value.params.repositoryInfoId as string
   )

   const buildId = computed(
      () => router.currentRoute.value.params.buildId as any as number
   )

   const tab = computed(
      () => router.currentRoute.value.query.tab as any as string
   )

   const policyId = computed(
      () => router.currentRoute.value.query.policyId as any as string
   )

   return {
      customerId,
      projectId,
      dashboardId,
      repositoryInfoId,
      buildId,
      tab,
      policyId
   }
}
